import { useFormik } from 'formik'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { catchAsync, checkAnyFileToBeUpload, CI_PROJECT_CATEGORY_TYPE_OPTIONS, COMMERCIAL_AND_INDUSTRIAL_OPTIONS, convertToCamelCaseNormal, ENUM_CUSTOMER_TYPE, ENUM_CUSTOMER_TYPE_OPTIONS, FormField, IS_SUCCESS_RESPONSE, NUMBER_REGEX, optionTypes, RESIDENTIAL_AND_SOCIETY_OPTIONS, RS_PROJECT_CATEGORY_TYPE_OPTIONS } from 'src/helpers'
import { getProjectCalculationApiRequest } from 'src/services/requests/Projects'
import { IProjectCalculationResponse } from 'src/services/requests/Projects/module'
import LoadingDialog from 'src/shadcn/components/custom/LoadingDialog'
import RenderFormComponents from 'src/shadcn/components/custom/RenderFormComponents'
import RoundedButton from 'src/shadcn/components/custom/RoundedButton'
import { Separator } from 'src/shadcn/components/ui'
import { ScrollArea } from 'src/shadcn/components/ui/scroll-area'
import { toast } from 'src/shadcn/components/ui/use-toast'
import { PROJECT_CATEGORY_TYPE, PROJECT_CATEGORY_WISE_FORM_FIELDS, PROJECT_OVERVIEW_FORM_FIELDS, ProjectCategoryWiseValidationSchemas, ProjectOverview, ProjectOverviewValidationSchema } from './modules'
import { GENERAL_PROJECT_INFO_FORM_FIELDS, generalProjectInfo, PROJECT_CATEGORY_WISE_FORM_FIELD, PROJECT_CATEGORY_WISE_FORM_STRUCTURE } from './modules2'
import { getAllUsersFromDrpDwnRequestApi } from 'src/services/requests/Auth'


type Props = {
    initial_values: any,
    submitBtnTitle: string,
    onSubmit: (e: any) => void
    isLoadingForm?: boolean
}

const ProjectForm = ({ initial_values, onSubmit, submitBtnTitle, isLoadingForm }: Props) => {


    const navigate = useNavigate()
    const [isLoading, setisLoading] = useState(false)
    const [isLoadingProjectCalculation, setisLoadingProjectCalculation] = useState(false)
    const [channelPartners, setChannelPartners] = useState<optionTypes[]>([])
    const [calculateResponse, setcalculateResponse] = useState<any>({})

    useEffect(() => {
        handleFetchChannelPartners()
        return () => { }
    }, [])



    const projectOverviewformik = useFormik<any>({
        initialValues: generalProjectInfo,
        // validationSchema: ProjectOverviewValidationSchema,
        onSubmit(values, formikHelpers) {

        },
    })

    // const CategoryValidationSchema = useMemo(() => projectOverviewformik.values.projectCategoryType && ProjectCategoryWiseValidationSchemas[projectOverviewformik.values.projectCategoryType as keyof object], [projectOverviewformik.values, ProjectCategoryWiseValidationSchemas])
    // const categoryWise = useMemo(() => projectOverviewformik.values.projectCategoryType && ProjectCategoryWiseValidationSchemas[projectOverviewformik.values.projectCategoryType as keyof object], [projectOverviewformik.values, ProjectCategoryWiseValidationSchemas])

    const projectCategoryformik = useFormik<any>({
        initialValues: {},
        // validationSchema: CategoryValidationSchema,
        onSubmit(values, formikHelpers) {
            handleSubmit()
        },
    })

    console.log("projectOverview :", { "error": projectOverviewformik.errors, "values": projectOverviewformik.values, });


    const handleFetchChannelPartners = catchAsync(async () => {
        return await getAllUsersFromDrpDwnRequestApi()
    }, (result: any) => {
        if (IS_SUCCESS_RESPONSE(result)) {
            result.data?.length > 0 && setChannelPartners(result.data.map((d: any) => {
                return {
                    label: d.firstName + " " + d.lastName,
                    value: d._id
                }
            }))
        }
    }, () => { setChannelPartners([]) })




    // // useEffect(() => {
    // //     if (initial_values) {
    // //         Object.keys(PROJECT_FORM_INITIAL_VALUES).map((key: any) => {

    // //             if (key === "unitRequiredPerMonth") {
    // //                 let monthlyUnits = (Number(initial_values["annualConsumption"]) / 12).toFixed(0)
    // //                 monthlyUnits = checkDecimal(monthlyUnits).toString()
    // //                 formik.setFieldValue(key, monthlyUnits || 0)
    // //                 let dailyConsumption = (parseFloat(monthlyUnits) / 30).toFixed(0)
    // //                 formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)
    // //                 // handleProjectCalculation(monthlyUnits)
    // //             }
    // //             else if (key === "termPlan" && initial_values[key]) {
    // //                 if (initial_values[key as any]) {
    // //                     let termPlan = initial_values[key as any].replace(NUMBER_REGEX, "")
    // //                     if (termPlan > 10) {
    // //                         formik.setFieldValue(key, Number(termPlan) / 12)
    // //                     }
    // //                     else initial_values[key] && formik.setFieldValue(key, (initial_values[key])?.toString().replace(NUMBER_REGEX, "") || null)
    // //                 }
    // //             }
    // //             else if (key === "connectionType" && initial_values[key]) {
    // //                 formik.setFieldValue(key, initial_values[key] ? CONNECTION_TYPE_OPTIONS.find((d: optionTypes) => d?.value?.toLowerCase() === initial_values[key]?.toLowerCase())?.value : null)
    // //             }
    // //             else formik.setFieldValue(key, Object.keys(initial_values).includes(key) ? initial_values[key] : 0 || null)

    // //             // if (key === "termPlan") {
    // //             //     initial_values[key] && formik.setFieldValue(key, (initial_values[key])?.toString()?.replace(NUMBER_REGEX, "") || null)
    // //             // }
    // //             // else formik.setFieldValue(key, initial_values[key] || null)

    // //         })
    // //         // formik.setValues(initial_values)
    // //     }
    // //     return () => { }
    // // }, [initial_values])

    // // useEffect(() => {
    // //     if (formik.errors) {
    // //          let errors =  Object.entries(formik.touched).map(([key,value]) =>  formik.errors[key as keyof object] )
    // //         if( errors && errors.length > 0) {
    // //             toast({
    // //                 variant: "destructive",
    // //                 title: "Kindly Fill Required Information",
    // //                 // description: Object.entries(formik.touched).map(([key,value]) =>  formik.errors[key as keyof object] ).join(", ")
    // //             })
    // //         }  
    // //     }

    // //     return () => { }
    // // }, [formik.errors])



    const handleSubmit = catchAsync(async () => {
        setisLoading(true)
        return await checkAnyFileToBeUpload({
            ...projectOverviewformik.values,
            ...projectCategoryformik.values
        })
    }, (result: any, data: any) => {
        console.log({ result, data });
        if (result[1]) {
            let newData: any = result[0];
            let postData = {
                projectOverview: Object.keys(projectOverviewformik.values).reduce((acc: any, key: any) => {
                    console.log({ key, value: newData[key] });

                    if (key == 'coordinates') {
                        let latlng = newData[key]?.split(",")
                        acc[key] = {
                            "latitude": latlng[0],
                            "longitude": latlng[1]
                        }

                    }
                    else acc[key] = newData[key]
                    return acc
                }, {}),
                projectData: Object.keys(projectCategoryformik.values).reduce((acc: any, key: any) => {
                    acc[key] = newData[key]
                    return acc
                }, {})
            }
            onSubmit(postData)
        }
        setisLoading(false)

    }, () => setisLoading(false))

    const handleProjectCalculation = catchAsync(async (unitRequiredPerMonth: string) => {
        setisLoadingProjectCalculation(true)
        return await getProjectCalculationApiRequest({ unitRequiredPerMonth })
    }, (result: IProjectCalculationResponse) => {
        console.log({ result });

        if (result && result?.data) {
            console.log("here");

            // result && result?.data && Object.entries(result?.data).map(([key, value]) => {
            //     console.log({ key, value });
            //     if (key === "termPlan") {
            //         value = value && parseInt(value?.toString()) / 12
            //         formik.setFieldValue(key, value)

            //     }
            //     else formik.setFieldValue(key, value)
            // })

            const dailyUnits = projectCategoryformik.values.unitRequiredPerMonth ? parseFloat(projectCategoryformik.values.unitRequiredPerMonth) / 30 : 0
            console.log({ dailyUnits });

            let termPlan = Number(result.data.termPlan) / 12 + ""

            projectCategoryformik.setFieldValue("unitConsumptionPerDay", dailyUnits)
            projectCategoryformik.setFieldValue("tokenValidity", result.data.termPlan)
            projectCategoryformik.setFieldValue("totalProjectCost", result.data.ProjectTotalprice)
            projectCategoryformik.setFieldValue("totalSupply", result.data.totalSupply)
            projectCategoryformik.setFieldValue("returnPerYearRate", result.data.intrestRate.replace(/[^0-9.]+/g, ""))
            projectCategoryformik.setFieldValue("interestRate", result.data.intrestRate.replace(/[^0-9.]+/g, ""))
            projectCategoryformik.setFieldValue("requiredInvestment", result.data.ProjectTotalprice)
            projectCategoryformik.setFieldValue("returnPerYearAmount", result.data.interestAmount)
            projectCategoryformik.setFieldValue("pricePerKwh", result.data.pricePerKWP)

            projectCategoryformik.setFieldValue("projectSize", result.data.projectSize)
            projectCategoryformik.setFieldValue("interestAmount", result.data.interestAmount)
            projectCategoryformik.setFieldValue("projectCost", result.data.projectCost)
            projectCategoryformik.setFieldValue("termPlan", termPlan)
            projectCategoryformik.setFieldValue("country", "India")
            setcalculateResponse(result.data)


        }
        setisLoadingProjectCalculation(false)
    }, () => {
        setisLoadingProjectCalculation(false)
    })

    const handleFormSubmit = () => {
        if (projectOverviewformik.isValid) {

            if (projectCategoryformik.isValid) {
                projectCategoryformik.handleSubmit()
            }
            else {
                Object.entries(projectCategoryformik.errors).map(([key, value]) => {
                    projectCategoryformik.setFieldError(key, "")
                    projectCategoryformik.setFieldTouched(key, true)
                })
                toast({
                    variant: "destructive",
                    title: "Form Validation Failed",
                    description: "Please enter valid data"
                })
            }

        }
        else {
            Object.entries(projectOverviewformik.errors).map(([key, value]) => {
                projectOverviewformik.setFieldError(key, "")
                projectOverviewformik.setFieldTouched(key, true)
            })
            toast({
                variant: "destructive",
                title: "Form Validation Failed",
                description: "Please enter valid data"
            })
        }
    }

    const PROJECT_CATEGORY_TYPE_OPTIONS = useMemo(() => {
        if ([ENUM_CUSTOMER_TYPE_OPTIONS.Commercial, ENUM_CUSTOMER_TYPE_OPTIONS.Industrial].includes(projectOverviewformik.values.customerType)) {
            return CI_PROJECT_CATEGORY_TYPE_OPTIONS
        }
        else if ([ENUM_CUSTOMER_TYPE_OPTIONS.Residential, ENUM_CUSTOMER_TYPE_OPTIONS.Society].includes(projectOverviewformik.values.customerType)) {
            return RS_PROJECT_CATEGORY_TYPE_OPTIONS
        }
        return []
    }, [projectOverviewformik.values.customerType])

    console.log({ error: projectOverviewformik.errors, values: projectOverviewformik.values, touched: projectOverviewformik.touched });
    console.log({ error: projectCategoryformik.errors, values: projectCategoryformik.values, touched: projectCategoryformik.touched });

    // useEffect(() => {
    //     formik.values.downpaymentByOfftaker && formik.setFieldValue("requiredInvestment", (Number(formik.values.totalProjectCost) - Number(formik.values.downpaymentByOfftaker) || 0))
    //     return () => { }
    // }, [formik.values.downpaymentByOfftaker])



    return (
        <div>
            <form onSubmit={(e: any) => {
                e.preventDefault()
                handleFormSubmit()
            }}>
                <ScrollArea className='h-[80vh] px-4 bg-gray-50'>
                    {/* <div className=' uppercase text-primary text-sm mt-4 mb-2'>Power Consumption Data</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-4 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialPowerConsumptionData).map((k: any) => {
                                let formfield: any = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined

                                if (k === "projectCategoryType") {
                                    if (formfield && formik.values.customerType && (formik.values.customerType === ENUM_CUSTOMER_TYPE.COMMERCIAL || formik.values.customerType === ENUM_CUSTOMER_TYPE.INDUSTRIA)) {
                                        formfield = { ...formfield, componentProps: { ...formfield?.componentProps, options: COMMERCIAL_AND_INDUSTRIAL_OPTIONS } }
                                    }
                                    else if (formfield && formik.values.customerType && (formik.values.customerType === ENUM_CUSTOMER_TYPE.RESIDENTIAL || formik.values.customerType === ENUM_CUSTOMER_TYPE.SOCIETY)) {
                                        formfield = { ...formfield, componentProps: { ...formfield?.componentProps, options: RESIDENTIAL_AND_SOCIETY_OPTIONS } }
                                    }
                                }
                                if (k === "imagesAttachments") {
                                    return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                }
                                else if (formfield?.dataKey === "unitRequiredPerMonth") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)}
                                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                            // console.log({ unitRequiredPerMonth: e.target.value });
                                            // let dailyConsumption =( Number(e.target.value.replace(NUMBER_REGEX,"")) / 30).toFixed(2)
                                            // formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)

                                        }}
                                        onKeyDown={(e: any) => {
                                            if (e && e.key === 'Enter') {
                                                if (e.target.value) {
                                                    // let monthlyConsumption = (Number(e.target.value.replace(NUMBER_REGEX, "")) / 12).toFixed(2)
                                                    // console.log({ monthlyConsumption });
                                                    // formik.setFieldValue("unitRequiredPerMonth", monthlyConsumption)
                                                    // let dailyConsumption =( parseFloat(monthlyConsumption) / 30).toFixed(2)
                                                    // formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)
                                                    // e.target.value && handleProjectCalculation(monthlyConsumption.replace(NUMBER_REGEX, ""))
                                                }
                                            }
                                        }}
                                    />
                                }
                                else if (formfield?.dataKey === "annualConsumption") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)}
                                        onBlur={(e: ChangeEvent<HTMLInputElement>) => {
                                            console.log({ annualConsumption: e.target.value });
                                            if (e.target.value) {
                                                let monthlyConsumption = (Number(e.target.value.replace(NUMBER_REGEX, "")) / 12).toFixed(0)
                                                console.log({ monthlyConsumption });
                                                formik.setFieldValue("unitRequiredPerMonth", monthlyConsumption)
                                                e.target.value && handleProjectCalculation(monthlyConsumption.replace(NUMBER_REGEX, ""))
                                                let dailyConsumption = (parseFloat(monthlyConsumption) / 30).toFixed(0)
                                                formik.setFieldValue("unitConsumptionPerDay", dailyConsumption)

                                            }
                                        }}
                                    />
                                }
                                else return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                    onChange={(e: any) => {
                                        if (formfield.dataKey === "pricePerKwh") {
                                            formik.setFieldValue(formfield.dataKey, e)
                                            let pricePerKwh = e || 0
                                            let projectCost = Number((pricePerKwh).toString().replace(NUMBER_REGEX, "") || 0) * (calculateResponse?.projectSize || 0)
                                            formik.setFieldValue("projectCost", projectCost)
                                        }

                                        else if (k === "customerType") {
                                            formik.setFieldValue("projectCategoryType", "")
                                            formik.setFieldValue(formfield.dataKey, e)
                                        }

                                        else formik.setFieldValue(formfield.dataKey, e)

                                    }} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Data</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialProjectData).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Contact Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialContactDetails).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Location Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialLocationDetails).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Documents</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialAttachments).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                if (k === "imagesAttachments") {
                                    return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} label={"Upload" + formfield.label} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                }
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} label={"Upload " + formfield.label} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Site Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialSiteData).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                if (k === "imagesAttachments") {
                                    return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />}</div>
                                }
                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => formik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>
                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Finance Data</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(initialProjectFinanceData).map((k: any) => {
                                const formfield: FormField | undefined = PROJECT_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                if (formfield?.dataKey === "downpaymentByOfftaker") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => {
                                            formik.setFieldValue(formfield.dataKey, e)
                                            // aconsole.log({ e });
                                            console.log({ e, projectCost: formik.values.totalProjectCost });
                                            if (e) {
                                                if (formik.values.totalProjectCost) {
                                                    let calculateOwnerShipPercentage = parseFloat(((e / parseFloat(formik.values.totalProjectCost)) * 100).toFixed(2))
                                                    calculateOwnerShipPercentage = Math.min(Math.max(calculateOwnerShipPercentage || 0, 0), 100)

                                                    let calculateRequiredInvestments = Number(formik.values.totalProjectCost) - Number(e)
                                                    calculateRequiredInvestments = Math.max(calculateRequiredInvestments || 0, 0)
                                                    console.log({ calculateOwnerShipPercentage, calculateRequiredInvestments });

                                                    formik.setFieldValue("offtakerOwnershipPercentage", calculateOwnerShipPercentage || 0)
                                                    formik.setFieldValue("requiredInvestment", calculateRequiredInvestments || 0)
                                                }
                                            }
                                            else {
                                                formik.setFieldValue("offtakerOwnershipPercentage", 0)
                                                formik.setFieldValue("requiredInvestment", calculateResponse?.ProjectTotalprice)
                                            }
                                            // formik.setFieldValue("offtakerOwnershipPercentage", 0)
                                        }}
                                    />
                                }
                                else if (formfield?.dataKey === "termPlan") {
                                    return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                        onChange={(e: any) => {
                                            formik.setFieldValue(formfield.dataKey, e)
                                            formik.setFieldValue("payBack", Number(e) * 12)
                                            formik.setFieldValue("tokenValidity", Number(e) * 12)
                                        }}
                                    />
                                }

                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={formik} {...formfield} value={formik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => {
                                    if (formfield.dataKey === "projectCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = e ? Number(e.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = formik.values.otherCost ? Number(formik.values.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("projectCost", projectCost)
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                    }

                                    else if (formfield.dataKey === "electicalCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = formik.values.otherCost ? Number(formik.values.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)

                                    }
                                    else if (formfield.dataKey === "structureCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = formik.values.otherCost ? Number(formik.values.otherCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                    }
                                    else if (formfield.dataKey === "otherCost") {
                                        formik.setFieldValue(formfield.dataKey, e)
                                        let projectCost = formik.values.projectCost ? Number(formik.values.projectCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let electricCost = formik.values.electicalCost ? Number(formik.values.electicalCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let structureCost = formik.values.structureCost ? Number(formik.values.structureCost?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let otherCost = e ? Number(e?.toString().replace(NUMBER_REGEX, "")) : 0
                                        let totalProjectCost = projectCost + electricCost + structureCost + otherCost
                                        formik.setFieldValue("totalProjectCost", totalProjectCost)
                                    }
                                    else formik.setFieldValue(formfield.dataKey, e)
                                }} />
                            })
                        }
                    </div> */}

                    <div className=' uppercase text-primary text-sm mt-4 mb-2'>Project Details</div>
                    <Separator className='my-2' />
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                        {
                            Object.keys(generalProjectInfo).map((k: any) => {
                                let formfield: any = GENERAL_PROJECT_INFO_FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined


                                if (k == "projectCategoryType") {
                                    formfield = {
                                        ...formfield,
                                        componentProps: {
                                            ...formfield.componentProps,
                                            options: PROJECT_CATEGORY_TYPE_OPTIONS
                                        }
                                    }
                                }
                                else if (k == "channelPartnerId") {
                                    formfield = {
                                        ...formfield,
                                        componentProps: {
                                            ...formfield.componentProps,
                                            options: channelPartners
                                        }
                                    }
                                }

                                return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={projectOverviewformik} {...formfield} value={projectOverviewformik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => projectOverviewformik.setFieldValue(formfield.dataKey, e)} />
                            })
                        }
                    </div>

                    {projectOverviewformik.values.projectCategoryType && <>
                        {
                            PROJECT_CATEGORY_WISE_FORM_STRUCTURE[projectOverviewformik.values.projectCategoryType as keyof object] && <>
                                {
                                    Object.entries(PROJECT_CATEGORY_WISE_FORM_STRUCTURE[projectOverviewformik.values.projectCategoryType as keyof object]).map(([key, objectKeys]) => {
                                        return <div>
                                            <div className=' uppercase text-primary text-sm mt-4 mb-2'>{key}</div>
                                            <Separator className='my-2' />
                                            <div className='grid grid-cols-3 gap-x-4 gap-y-2 my-2 '>
                                                {
                                                    Object.keys(objectKeys as keyof object).map((k: any) => {
                                                        let FORM_FIELDS: any = PROJECT_CATEGORY_WISE_FORM_FIELD[projectOverviewformik.values.projectCategoryType as keyof object]
                                                        if (FORM_FIELDS) {
                                                            let formfield: any = FORM_FIELDS.find((filed: FormField) => filed.dataKey === k) || undefined
                                                            if (formfield && ["landOwnershipDocuments", "recentElectricityBills"].includes(k)) {
                                                                return formfield && <div key={`create-project-form-field-${formfield.dataKey}`} className='col-span-3'>{<RenderFormComponents formik={projectCategoryformik} {...formfield} value={projectCategoryformik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }} onChange={(e: any) => projectCategoryformik.setFieldValue(formfield.dataKey, e)} />}</div>
                                                            }
                                                            return formfield && <RenderFormComponents key={`create-project-form-field-${formfield.dataKey}`} formik={projectCategoryformik} {...formfield} value={projectCategoryformik.values[formfield.dataKey as keyof object]} componentProps={{ ...formfield.componentProps }}
                                                                onChange={(e: any) => {
                                                                    if (formfield?.dataKey) {
                                                                        formfield?.dataKey && projectCategoryformik.setFieldValue(formfield.dataKey, e)
                                                                    }
                                                                }} />
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </>
                        }

                    </>}


                </ScrollArea>
                <div className='my-6 flex justify-center space-x-2'>
                    <RoundedButton type='button' disabled={isLoading || isLoadingForm} variant={'secondary'} onClick={() => navigate(-1)} >Back</RoundedButton>
                    <RoundedButton type='submit' disabled={isLoading || isLoadingForm}  >{submitBtnTitle}</RoundedButton>
                </div>
            </form>
            <LoadingDialog isOpen={isLoadingProjectCalculation} message='Calculating project cost' />
            <LoadingDialog isOpen={isLoading} message='Uploading Documents' />
        </div>

    )
}

export default ProjectForm