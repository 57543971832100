
export const ANALYSIS_DATA = [

  {
    title: "Total Rooftop Requests",
    value: "0",
    dataKey: "rooftopData",
    prefix: "#",
    icon: null
  },
  {
    title: "Total Land Requests",
    value: "0",
    dataKey: "landData",
    prefix: "#",
    icon: null
  },
  {
    title: "Total New Construction Requests ",
    value: "0",
    dataKey: "newconstructionData",
    prefix: "#",
    icon: null
  },
  {
    title: "Total Sell Project Request",
    value: "0",
    dataKey: "sellProjectsData",
    prefix: "#",
    icon: null
  },
]

export const SCHEDULE_CALL_REQUESTS_ANALYSIS_DATA = [

  {
    title: "Pending",
    value: "0",
    dataKey: "PENDING",
    prefix: "#",
    icon: null
  },
  {
    title: "Accepted",
    value: "0",
    dataKey: "ACCEPT",
    prefix: "#",
    icon: null
  },

 
]