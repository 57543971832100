
import { useEffect, useMemo, useState } from 'react'
import { Value } from '@wojtekmaj/react-daterange-picker/dist/cjs/shared/types';
import { columns } from 'src/modules/Users/AllUsers/components/columns'
import { DataTable } from 'src/modules/Users/AllUsers/components/data-table'
import { QueryRequestProvider, useQueryRequest } from './core/QueryRequestProvider'
import { QueryResponseProvider, useQueryResponseData } from './core/QueryResponseProvider'
import DateInput from 'src/shadcn/components/custom/DateInpute/DateInput'
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import dayjs from 'dayjs';
import { useLocation, useSearchParams } from 'react-router-dom';

type Props = {

}

const Main = () => {
    const apiResp = useQueryResponseData()
    const { state, updateState } = useQueryRequest()
    const [value, onChange] = useState<Value>([dayjs().startOf('month').toDate(), dayjs().endOf('month').toDate()]);
    const tableData = useMemo(() => apiResp.map((d: any, indx: number) => {
        return {
            srNo: indx + 1,
            ...d,
        }
    }), [apiResp])


    console.log({ state: state.filter });


    return (
        <div className='p-4'>
            <div className='flex justify-end mb-4'>
                <DateRangePicker onChange={(e: any) => {
                    onChange(e)
                    updateState({
                        filter: {
                            ...state.filter,
                            startdate: dayjs(e[0]).format('DD/MM/YYYY'),
                            enddate: dayjs(e[1]).format('DD/MM/YYYY')
                        }
                    })

                }} value={value} calendarIcon={false} />
            </div>
            <DataTable columns={columns} data={tableData || []} />
        </div>
    )
}

const AllUserLIstMain = (props: Props) => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export default AllUserLIstMain