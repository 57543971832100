import { ClassNameValue } from "tailwind-merge";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../AssetHelpers";



const convertArrayToOptionType = (data: string[]) => data.map((d: string) => ({ label: d, value: d }))



export const ICON_PNG_OCT_LOGO = toAbsoluteUrl("/media/png/oct-logo.png")

export const ERROR_CODES = [400, 500, 404];

export const ROLES = {
    // ADMIN: "ADMIN",
    ADMIN_USER: "ADMIN_USER",
    SUPER_ADMIN: "SUPER_ADMIN",
    STAKEHOLDER: "STAKEHOLDER",
    EPC_CONTRACTOR: "EPC_CONTRACTOR",
    MANUFACTURER: "MANUFACTURER"
}


export const ROLES_OPTIONS = {
    // ADMIN: "ADMIN",
    ADMIN_USER: "ADMIN USER",
    SUPER_ADMIN: "SUPER ADMIN",
    STAKEHOLDER: "STAKEHOLDER",
    EPC_CONTRACTOR: "EPC CONTRACTOR",
    MANUFACTURER: "MANUFACTURER"
}

export type optionTypes = {
    label: string,
    value: string,
    icons?: any
}

export type componentType = 'input' | 'inputicon' | 'options' | 'date_picker' | 'file_picker' | 'textarea';

export interface FormField {
    label: string;
    dataKey: string;
    value?: string,
    componentType: componentType,
    componentProps?: {
        type?: 'email' | 'password' | 'phone' | 'text' | 'url' | "number",
        className?: string,
        placeholder?: string
        regex?: RegExp,
        maxLength?: number,
        uppercase?: boolean,
        options?: optionTypes[],
        inputSuffixIcon?: any,
        inputPrefixIcon?: any,
        required?: boolean,
        readonly?: boolean,
        disabled?: boolean,
        showCharacterLength?: boolean,
        fileSelectionType?: "single" | "multi",
        numberFormatted?: boolean,
        labelStyle?: ClassNameValue,
        description?: string
        optionsPlaceholder?: string,
        conditionalDisabled?: {
            formKey: string,
            expectedValue: string
        },
        hide?: {
            formKey: string,
            expectedValue: string
        },
        conditionalHide?: {
            formKey: string,
            expectedValue: string | string[]
        },
        disablePreviousDates?: string,
        disableFeaturesDates?: string,
        optionsSearchable?: boolean
    }
}


export const USER_ROLES = {
    "INVESTOR": "investor",
    "OFF_TAKER": "offtakers"
}


export const successTheme = {
    background: '#fff',
    color: "hsl(var(--primary))",
    borderRadius: '8px',
    padding: '16px',
};

export const errorTheme = {
    background: '#FF5722',
    color: '#FFF',
    borderRadius: '8px',
    padding: '16px',
};

export const PROJECT_TYPES = [
    // { label: 'Matchmaking', value: 'Matchmaking' },
    { label: 'BOOT', value: 'BOOT' },
    // { label: 'EaaS', value: 'EaaS' },
    // { label: 'VPP', value: 'VPP' },
];

export const NUMBER_REGEX = /[^0-9.]+/g
export const LAT_LONG_REGEX = /[^0-9., ]+/g
export const EMAIL_REGEX = /[^a-z0-9.@]+/g
export const NAME_REGEX = /[^A-z ]+/g
export const ADDRESS_REGEX = /[^A-z0-9/-_, ]+/g
export const EXTRACT_NUMBERS_REGEX = /[^A-z0-9.]+/g
export const EXTRACT_STRING_REGEX = /[^A-z ]+/g
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,18}$/


export const DUMMY_OPTIONS = [
    {
        label: 'Type 1',
        value: 'type1',
    },
    {
        label: 'Type 2',
        value: 'type2',
    },
    // Add more options as needed
]


export const YES_NO_OPTIONS = [
    {
        label: 'Yes',
        value: 'Yes',
    },
    {
        label: 'No',
        value: 'No',
    },
    // Add more options as needed
]


export const AUTHORIZATION_PARTNER_OPTIONS = [
    {
        label: 'Gold',
        value: 'gold',
    },
    {
        label: 'platinum',
        value: 'platinum',
    },
    {
        label: 'Silver',
        value: 'silver',
    },
    // Add more options as needed
]


export const EPC_CONTRACTORS_SPECIALIZATIONS = [
    "Solar farm design and engineering",
    "Procurement of solar panels, inverters, and other equipment",
    "Construction and installation of solar farms",
    "Routine maintenance and repair of solar farms",
    "Performance monitoring and optimization",
    "Troubleshooting and fault resolution",
    "Electrical infrastructure design and installation",
    "Electrical testing and commissioning",
    "Electrical maintenance and repair",
    "Site preparation and grading",
    "Foundation design and installation",
    "Road construction and maintenance",
    "Environmental impact assessments",
    "Permitting and compliance",
    "Wildlife habitat assessments and mitigation"
]

export const MANUFACTURERS_SPECIALIZATIONS = [
    "Monocrystalline silicon solar panels",
    "Polycrystalline silicon solar panels",
    "Thin-film solar panels",
    "Bifacial solar panels",
    "String inverters",
    "Central inverters",
    "Microinverters",
    "Power optimizers",
    "Fixed-tilt mounting systems",
    "Tracking mounting systems",
    "Roof-top mounting systems",
    "Ground-mounted systems",
    "Lithium-ion battery systems",
    "Lead-acid battery systems",
    "Flow battery systems",
    "Sodium-ion battery systems",
    "Data loggers",
    "Monitoring software",
    "Control systems for inverters and trackers",
    "Weather stations"
]

export const OTHER_SPECIALIZATIONS = [
    "Residential solar installations",
    "Commercial solar installations",
    "Utility-scale solar installations",
    "String inverter installation",
    "Central inverter installation",
    "Microinverter installation",
    "Electrical testing and inspection",
    "Commissioning of electrical systems",
    "Electrical safety auditing",
    "Preventive maintenance",
    "Corrective maintenance",
    "Predictive maintenance",
    "Energy audits and assessments",
    "Energy efficiency recommendations",
    "Energy management and monitoring"
]


export const SPECIALIZATION_OPTIONS = [...EPC_CONTRACTORS_SPECIALIZATIONS, ...MANUFACTURERS_SPECIALIZATIONS, ...OTHER_SPECIALIZATIONS].map((d: any) => ({ label: d, value: d }))

export const IMAGE_DOCUMENT_VALIDATION_SCHEMA = Yup.mixed()
    .test('fileType', 'Only jpg, png files are allowed', (value: any) => {

        if (value && value instanceof File) {
            const fileType = value.type;
            return (
                fileType === 'image/jpeg' ||
                fileType === 'image/png'
                // fileType === 'application/pdf' ||
                // fileType === 'text/csv'
            );
        }
        else if (value && typeof value === "string") {
            return true
        }
    })
    .test('fileSize', 'File size must be less than 2MB', (value: any) => {
        if (value && value instanceof File) {
            const fileSize = value.size;
            return fileSize <= 2 * 1024 * 1024; // 5MB
        }
        else if (value && typeof value === "string") {
            return true
        }
    });


export const ALL_DOCUMENT_VALIDATION_SCHEMA = Yup.mixed()
    .test('fileType', 'Only jpg, png files are allowed', (value: any) => {

        if (value && value instanceof File) {
            const fileType = value.type;
            return (
                fileType === 'image/jpeg' ||
                fileType === 'image/png' ||
                fileType === 'application/pdf' ||
                fileType === 'text/csv'
            );
        }
        else if (value && typeof value === "string") {
            return true
        }
    })
    .test('fileSize', 'File size must be less than 2MB', (value: any) => {

        if (value && value instanceof File) {
            const fileSize = value.size;
            return fileSize <= 2 * 1024 * 1024; // 5MB
        }
        else if (value && typeof value === "string") {
            return true
        }
    });


export const PDF_AND_IMAGE_DOCUMENT_VALIDATION_SCHEMA = Yup.mixed()
    .test('fileType', 'Only jpg, png and pdf files are allowed', (value: any) => {

        if (value && value instanceof File) {
            const fileType = value.type;
            return (
                fileType === 'image/jpeg' ||
                fileType === 'image/png' ||
                fileType === 'application/pdf'
            )
        }
        else if (value && typeof value === "string") {
            return true
        }
    })
    .test('fileSize', 'File size must be less than 5MB', (value: any) => {

        if (value && value instanceof File) {
            const fileSize = value.size;
            return fileSize <= 2 * 1024 * 1024; // 5MB
        }
        else if (value && typeof value === "string") {
            return true
        }
    });


export const VIDEO_DOCUMENT_VALIDATION_SCHEMA = Yup.mixed()
    .test('fileType', 'Only video files are allowed', (value: any) => {
        if (value && value instanceof File) {
            console.log({ videoAttachments: value });
            const fileType = value.type;
            console.log({ fileType });
            return fileType.includes("video/");
        }
        else if (value && typeof value === "string") {
            return true
        }
    })
    .test('fileSize', 'File size must be less than 5MB', (value: any) => {
        if (value && value instanceof File) {
            const fileSize = value.size;
            return fileSize <= 5 * 1024 * 1024; // 5MB
        }
        else if (value && typeof value === "string") {
            return true
        }
    })

export const MULTI_IMAGE_ATTACHMENT_VALIDATION_SCHEMA = Yup.array().of(
    Yup.mixed()
        .test('fileType', 'Only jpg, png files are allowed', (value: any) => {
            if (value && value instanceof File) {
                const fileType = value.type;
                console.log({ fileType });

                return (
                    fileType === 'image/jpeg' || fileType === 'image/jpg' ||
                    fileType === 'image/png'
                    // fileType === 'application/pdf' ||
                    // fileType === 'text/csv'
                );
            }
            else if (value && typeof value === "string") {
                return true
            }
        })
        .test('fileSize', 'File size must be less than 5MB', (value: any) => {

            if (value && value instanceof File) {
                const fileSize = value.size;
                return fileSize <= 2 * 1024 * 1024; // 5MB
            }
            else if (value && typeof value === "string") {
                return true
            }
        })

)


export const PROJECT_CATEGORY_OPTIONS = [
    {
        "label": "Community Solar",
        "value": "Community Solar"
    },
    {
        "label": "Ground-Mounted",
        "value": "Ground-Mounted"
    },
    {
        "label": "Floating Solar",
        "value": "Floating Solar"
    },
    {
        "label": "Solar Parks",
        "value": "Solar Parks"
    },
    {
        "label": "Rooftop Solar",
        "value": "Rooftop Solar"
    },
    {
        "label": "Carport Solar",
        "value": "Carport Solar"
    },
    {
        "label": "Hybrid (Solar + Wind +Battery)",
        "value": "Hybrid (Solar + Wind +Battery)"
    },
    {
        "label": "BESS (Energy Storage System)",
        "value": "BESS (Energy Storage System)"
    }
]

export const SYSTEM_TYPE_OPTIONS = [
    {
        "label": "Photovoltaic(PV)",
        "value": "Photovoltaic(PV)"
    },
    {
        "label": "Solar Thermal",
        "value": "Solar Thermal"
    },
    {
        "label": "Off-Grid Solar",
        "value": "Off-Grid Solar"
    },
    {
        "label": "On-Grid Solar",
        "value": "On-Grid Solar"
    },
    {
        "label": "Hybrid Systems",
        "value": "Hybrid Systems"
    },
    {
        "label": "Concentrated Solar Power(CSP)",
        "value": "Concentrated Solar Power(CSP)"
    },
    {
        "label": "Building-Integrated Photovoltaic(BIPV)",
        "value": "Building-Integrated Photovoltaic(BIPV)"
    },
    {
        "label": "Floating Solar",
        "value": "Floating Solar"
    },
    {
        "label": "Agrivoltaics (Agro-Solar)",
        "value": "Agrivoltaics (Agro-Solar)"
    }
]



export const YEARS_RANGE_OPTIONS = [
    {
        "label": "Less than 1 Years",
        "value": "Less than 1 Years"
    },
    {
        "label": "1-5 Years",
        "value": "1-5 Years"
    },
    {
        "label": "5-10 Years",
        "value": "5-10 Years"
    },
    {
        "label": "10-15 Years",
        "value": "10-15 Years"
    },
    {
        "label": "15-20 Years",
        "value": "15-20 Years"
    },
    {
        "label": "More than 20 Years",
        "value": "More than 20 Years"
    }
]

export const CONNECTION_TYPE_OPTIONS = [
    { label: "Single Phase", value: "Single Phase" },
    { label: "Three Phase (LV)", value: "Three Phase (LV)" },
    { label: "Three Phase (MV)", value: "Three Phase (MV)" }
]

export const TYPE_OF_SPACE_ENUM = {
    "Rooftop": "Rooftop",
    "Land": "Land"
}

export const TYPE_OF_SPACE_OPTIONS = [{
    "label": "Rooftop",
    "value": "Rooftop"
}, {
    "label": "Land",
    "value": "Land"
}]

export const ROOF_MATERIAL_OPTIONS = [
    {
        "label": "RCC",
        "value": "RCC"
    },
    {
        "label": "Metal Sheet",
        "value": "Metal Sheet"
    },
    {
        "label": "Coaler",
        "value": "Coaler"
    },
    {
        "label": "Other",
        "value": "Other"
    }
]

export const ROOF_ORIENTATION = [
    {
        label: "North",
        value: "North",
    },
    {
        label: "South",
        value: "South",
    },
    {
        label: "East",
        value: "East",
    },
    {
        label: "West",
        value: "West",
    },
];

export const ROOFTOP_MATERIAL_OPTIONS = [
    {
        label: "Concrete",
        value: "Concrete",
    },
    {
        label: "Tin",
        value: "Tin",
    },
    {
        label: "GI Sheet",
        value: "GI Sheet",
    },
    {
        label: "Clay Tiles",
        value: "Clay Tiles",
    },
    {
        label: "Fiber Sheets",
        value: "Fiber Sheets",
    },
    {
        label: "Other",
        value: "Other",
    },

]

export const SHADING_OPTIONS = [
    {
        label: "No Shading",
        value: "No Shading",
    },
    {
        label: "Partial Shading",
        value: "Partial Shading",
    },
    {
        label: "Full Shading",
        value: "Full Shading",
    },
];

export const PROPERTY_TYPE_OPTIONS = [{
    "label": "Rented",
    "value": "Rented"
}, {
    "label": "Ownership",
    "value": "Ownership"
}];

export const ROOFTOP_PROPERTY_TYPE_OPTIONS = [
    {
        label: "Residential",
        value: "Residential",
    },
    {
        label: "Commercial",
        value: "Commercial",
    },
    {
        label: "Industrial",
        value: "Industrial",
    },
    {
        label: "Institutional",
        value: "Institutional",
    },];

export const ROOF_TYPE_OPTIONS = [
    {
        label: "Flat Roof",
        value: "Flat Roof"
    },
    {
        label: "Sloped Roof",
        value: "Sloped Roof"
    },
    {
        label: "Metal Roof",
        value: "Metal Roof"
    },
    {
        label: "RCC",
        value: "RCC"
    },
    {
        label: "Asbestos",
        value: "Asbestos"
    },
    {
        label: "Tiles",
        value: "Tiles"
    },
    {
        label: "Other",
        value: "Other"
    }
];

export const SOLAR_SYSTEM_TYPE_OPTIONS = [
    {
        label: "On-Grid",
        value: "On-Grid"
    },
    {
        label: "Off-Grid",
        value: "Off-Grid"
    },
    {
        label: "Hybrid",
        value: "Hybrid"
    }
];

export const AVAILABLE_SPACE_UNITS_OPTIONS = [
    { label: "SqFt", value: "SqFt" },
    { label: "meter", value: "m" },
    { label: "feet", value: "ft" },
    { label: "SqM", value: "SqM" },
]

export const DAY_TIME_UNITS_OPTIONS = [
    { label: "Days", value: "Days" },
    { label: "Years", value: "Years" },
    { label: "Months", value: "Months" },
]


export const PROJECT_OWNERSHIP_TYPE_OPTIONS = [
    { label: "Independent Power Producer", value: "Independent Power Producer" },
    { label: "Utility-Owned", value: "Utility-Owned" },
    { label: "Joint Venture", value: "Joint Venture" },
    { label: "Special Purpose Vehicle", value: "Special Purpose Vehicle" },
    { label: "Government-Owned", value: "Government-Owned" }
]

export const DC_COMMISIONING_TYPE_OPTIONS = [
    { label: "Yes", value: "Yes" },
    { label: "No", value: "No" }
]

export const TERM_PLAN_OPTIONS = [...Array(10)].map((_, indx: number) => {
    return { label: (indx + 1) + " Years", value: (indx + 1).toString() }
})

export const SESSIONS = {
    FORGOT_PASSWORD: "FORGOT-PASSWORD",
    REGISTRATION: "REGISTRATION"
}


export const STATUS = {
    New: 'New',
    Approved: 'Approved',
    Active: 'Active',
    Completed: 'Completed',
    Rejected: 'Rejected',
    Closed: 'Closed'
}

export type ISTATUS =
    | "New"
    | "Approved"
    | "Active"
    | "Completed"
    | "Rejected"
    | "Closed";


export const IS_SUCCESS_RESPONSE = (result: any) => [200, 201].includes(result.status || result.code) && (result?.success ? result?.success : true)

export const BUILDING_TYPES = [{
    "label": "Residential",
    "value": "Residential"
}, {
    "label": "Commercial",
    "value": "Commercial"
}, {
    "label": "Industrial",
    "value": "Industrial"
}];


export const APPROVAL_REJECT_OPTIONS = [{
    "label": "Approved",
    "value": "Approved"
}, {
    "label": "Rejected",
    "value": "Rejected"
}];


export const ENUM_APPROVE_REJECT = {
    "Approved": "Approved",
    "Rejected": "Rejected"
}


export const TRANSMISSION_CURRENT_STATUS_OPTIONS = [{
    "label": "In-Progress",
    "value": "In_Progress"
}, {
    "label": "Completed",
    "value": "Completed"
}];


export const LAND_OWNERSHIP_STATUS_OPTIONS = [{
    "label": "Freehold",
    "value": "Freehold"
}, {
    "label": "Leasehold",
    "value": "Leasehold"
}];

export const LAND_ACQUISITION_STATUS_OPTIONS = [{
    "label": "Completed",
    "value": "Completed"
}, {
    "label": "In-Progress",
    "value": "In_Progress"
},
{
    "label": "Pending",
    "value": "Pending"
}];

export const ENQUIRY_SERVICE_TYPES: optionTypes[] = [
    {
        label: "Land Owner",
        value: "landOwner"
    },
    {
        label: "Rooftop Solar",
        value: "roofTopSolar"
    },
    {
        label: "Ground Mounted",
        value: "groundMounted"
    },
    {
        label: "Project Rights",
        value: "projectRights"
    },
    {
        label: "New Constructed Projects",
        value: "newConstructedProjects"
    },
    {
        label: "Sell Existing Project",
        value: "sellExistingProject"
    },
    {
        label: "Finance",
        value: "finance"
    },
    {
        label: "Solar EPC",
        value: "solarEPC"
    },
    {
        label: "Solar O&M",
        value: "solarOandM"
    }
];

export const TYPE_OF_SOLAR_PLANT_OPTIONS = [{
    "label": "Ground-Mounted",
    "value": "Ground-Mounted"
}, {
    "label": "Rooftop",
    "value": "Rooftop"
}, {
    "label": "Floating",
    "value": "Floating"
}, {
    "label": "Hybrid",
    "value": "Hybrid"
}];

export const PROJECT_CONNECTION_TYPE_OPTIONS = [{
    "label": "On-Grid",
    "value": "On-Grid"
}, {
    "label": "Off-Grid",
    "value": "Off-Grid"
}, {
    "label": "Hybrid",
    "value": "Hybrid"
}];


export const PROJECT_PLANT_STATUS_OPTIONS = [{
    "label": "Under Development",
    "value": "Under Development"
}, {
    "label": "Operational",
    "value": "Operational"
}, {
    "label": "Commissioned",
    "value": "Commissioned"
}, {
    "label": "Completed",
    "value": "Completed"
}];


export const PLANT_STATUS_OPTIONS_2 = [{
    "label": "Under Maintenance",
    "value": "Under Maintenance"
}, {
    "label": "Operational",
    "value": "Operational"
}, {
    "label": "Partially Operational",
    "value": "Partially Operational"
},];

export const PROJECT_SOLAR_TECHNOLOGY_OPTIONS = [{
    "label": "Photovoltaic (PV)",
    "value": "Photovoltaic (PV)"
}, {
    "label": "Concentrated Solar Power (CSP)",
    "value": "Concentrated Solar Power (CSP)"
}, {
    "label": "Other",
    "value": "Other"
}];

export const PROJECT_PROJECT_OWNERSHIP_TYPE_OPTIONS = [{
    "label": "Developer",
    "value": "Developer"
}, {
    "label": "Owner",
    "value": "Owner"
}];

export const OWNERSHIP_STATUS_OPTIONS = [{
    "label": "Owned",
    "value": "Owned"
}, {
    "label": "Joint Ownership",
    "value": "Joint Ownership"
}, {
    "label": "Leased",
    "value": "Leased"
}];


export const PROJECT_SOLAR_MODULES_TYPE_OPTIONS = [{
    "label": "Monocrystalline",
    "value": "Monocrystalline"
}, {
    "label": "Polycrystalline",
    "value": "Polycrystalline"
}, {
    "label": "Thin Film",
    "value": "Thin Film"
}, {
    "label": "Other",
    "value": "Other"
}];

// New constructed projects field arrays
export const TYPE_OF_SOLAR_PLANT = [
    {
        label: "Ground-Mounted",
        value: "Ground-Mounted",
    },
    {
        label: "Rooftop",
        value: "Rooftop",
    },
    {
        label: "Floating",
        value: "Floating",
    },
    {
        label: "Hybrid",
        value: "Hybrid",
    },
];

export const CONNECTION_TYPE = [
    {
        label: "On-Grid",
        value: "On-Grid",
    },
    {
        label: "Off-Grid",
        value: "Off-Grid",
    },
    {
        label: "Hybrid",
        value: "Hybrid",
    },
];

export const PLANT_STATUS = [
    {
        label: "Under Development",
        value: "Under Development",
    },
    {
        label: "Operational",
        value: "Operational",
    },
    {
        label: "Commissioned",
        value: "Commissioned",
    },
    {
        label: "Completed",
        value: "Completed",
    },
];

export const SOLAR_TECHNOLOGY_USED = [
    {
        label: "Photovoltaic (PV)",
        value: "Photovoltaic (PV)",
    },
    {
        label: "Concentrated Solar Power (CSP)",
        value: "Concentrated Solar Power (CSP)",
    },
    {
        label: "Other",
        value: "Other",
    },
];

export const PROJECT_OWNERSHIP_TYPE = [
    {
        label: "Developer",
        value: "Developer",
    },
    {
        label: "Owner",
        value: "Owner",
    },
];


export const TYPE_OF_SOLAR_MODULES_USED = [
    {
        label: "Monocrystalline",
        value: "Monocrystalline",
    },
    {
        label: "Polycrystalline",
        value: "Polycrystalline",
    },
    {
        label: "Thin Film",
        value: "Thin Film",
    },
    {
        label: "Other",
        value: "Other",
    },
];


export const DOCUMENTS_UPLOAD = [
    {
        label: "Power Purchase Agreement",
        value: "Power Purchase Agreement",
    },
    {
        label: "Ownership Documents",
        value: "Ownership Documents",
    },
    {
        label: "Legal Clearance",
        value: "Legal Clearance",
    },
    {
        label: "Other",
        value: "Other",
    },
];

export const COMMERCIAL_AND_INDUSTRIAL_OPTIONS = ['Buy Project Rights', 'Buy Existing Projects', 'Buy New Construction Projects', 'Lease Roof Areas', 'Offer Land For Utility Scale Project'].map((d: any) => ({ label: d, value: d }))

export const RESIDENTIAL_AND_SOCIETY_OPTIONS = ['Competitive finance', 'Solar EPC', 'Rooftop Solar'].map((d: any) => ({ label: d, value: d }))



export const ENUM_CUSTOMER_TYPE = {
    COMMERCIAL: 'Commercial',
    INDUSTRIA: 'Industrial',
    RESIDENTIAL: 'Residential',
    SOCIETY: 'Society'
}

export const CUSTOMER_TYPE_OPTIONS = ['Commercial', 'Industrial', 'Residential', 'Society'].map((d: any) => ({ label: d, value: d }))
export const ENUM_CUSTOMER_TYPE_OPTIONS = {
    'Commercial': 'Commercial',
    'Industrial': 'Industrial',
    'Residential': 'Residential',
    'Society': 'Society',
}

export const PROJECT_CATEGORY_TYPE_OPTIONS = [
    "Lease Roof Areas",
    "Offer Land For Utility Scale Project",
    "Buy Project Rights",
    "Solar EPC",
    "Buy Existing Projects",
    "Competitive finance",
    "Buy New Construction Projects",
    "Rooftop Solar"
].map((d: any) => ({ label: d, value: d }))

export const CI_PROJECT_CATEGORY_TYPE_OPTIONS = [
    "RE Project Capacity", "RE Project Rights", "New RE Project Construction", "Land for RE Projects", "Rooftop for RE Projects", "Project Finance", "C&I RE Projects"
].map((d: any) => ({ label: d, value: d }))

export const RS_PROJECT_CATEGORY_TYPE_OPTIONS = [
    "Lease Roof Areas",
    "Offer Land For Utility Scale Project",
    "Buy Project Rights",
    "Solar EPC",
    "Buy Existing Projects",
    "Competitive finance",
    "Buy New Construction Projects",
    "Rooftop Solar"
].map((d: any) => ({ label: d, value: d }))


export const PROJECT_PROJECT_CONSTRUCTION_PROPERTY_DETAILS_LAND_TYPE_OPTIONS = convertArrayToOptionType(['Private', 'Public', 'Leasehold'])
export const PROJECT_PROJECT_CONSTRUCTION_CONSTRUCTION_TIMELINE_STATUS_OPTIONS = convertArrayToOptionType(['Not Started', 'In Progress', 'Completed']);
export const PROJECT_PROJECT_CONSTRUCTION_CONSTRUCTION_PHASE_OPTIONS = convertArrayToOptionType(['Planning', 'Execution', 'Completed']);
export const PROJECT_PROJECT_CONSTRUCTION_SUBSTATION_TRANSMISSION_STATUS_OPTIONS = convertArrayToOptionType(['Work in Progress', 'Completed']);
export const PROJECT_PROJECT_CONSTRUCTION_APPROVAL_PERMITS_REQUIRED_OPTIONS = convertArrayToOptionType(['Environmental Clearance', 'Local Body Approval', 'Grid Connection Approval', 'Other']);
export const PROJECT_PROJECT_CONSTRUCTION_PERMIT_STATUS_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);
export const PROJECT_CI_AND_RE_PROJECTS_BUILDING_TYPE_OPTIONS = convertArrayToOptionType(['Residential', 'Commercial', 'Industrial', 'Institutional']);
export const PROJECT_CI_AND_RE_PROJECTS_ROOF_TYPE_OPTIONS = convertArrayToOptionType(['RCC', 'Tiled', 'Metal', 'Asbestos']);
export const PROJECT_CI_AND_RE_PROJECTS_ROOF_CONDITION_OPTIONS = convertArrayToOptionType(['Good', 'Fair', 'Poor']);
export const PROJECT_CI_AND_RE_PROJECTS_OWNERSHIP_STATUS_OPTIONS = convertArrayToOptionType(['Freehold', 'Leasehold']);
export const PROJECT_CI_AND_RE_PROJECTS_ACQUISITION_STATUS_OPTIONS = convertArrayToOptionType(['Completed', 'In Progress', 'Pending']);
export const PROJECT_CI_AND_RE_PROJECTS_SYSTEM_TYPE_OPTIONS = convertArrayToOptionType(['On-grid', 'Off-grid', 'Hybrid']);
export const PROJECT_RE_PROJECT_CAPACITY_COMPANY_RATING_OPTIONS = convertArrayToOptionType(['A+', 'A', 'B+', 'B', 'C', 'D']);
export const PROJECT_RE_PROJECT_CAPACITY_INDUSTRY_TYPE_OPTIONS = convertArrayToOptionType(['Manufacturing', 'IT', 'Retail', 'Hospitality', 'Healthcare', 'Other']); // Added 'Other' for flexibility
export const PROJECT_RE_PROJECT_CAPACITY_PREFERRED_RENEWABLE_ENERGY_SOURCE_OPTIONS = convertArrayToOptionType(['Solar', 'Wind', 'Biomass', 'Hybrid']);
export const PROJECT_RE_PROJECT_CAPACITY_PREFERRED_ENERGY_CONTRACT_DURATION_OPTIONS = convertArrayToOptionType(['1 year', '3 years', '5 years', '10 years']);
export const PROJECT_RE_PROJECT_CAPACITY_INTERESTED_IN_PPA_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_PPA_TERM_PREFERRED_OPTIONS = convertArrayToOptionType(['Short Term (<5 years)', 'Long Term (5+ years)']);
export const PROJECT_RE_PROJECT_CAPACITY_PREFERRED_CONNECTION_VOLTAGE_OPTIONS = convertArrayToOptionType(['LT', 'HT', 'EHV']);
export const PROJECT_RE_PROJECT_CAPACITY_WILLING_TO_PARTICIPATE_IN_P2P_TRADING_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_HAS_SUSTAINABILITY_GOALS_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_RENEWABLE_ENERGY_CERTIFICATION_REQUIRED_OPTIONS = convertArrayToOptionType(['Yes', 'No']);
export const PROJECT_RE_PROJECT_CAPACITY_EXISTING_SUBSTATION_CONNECTION_VOLTAGE_OPTIONS = convertArrayToOptionType(['11 KV', '33 KV', '66 KV', '132 KV', '220 KV', '400 KV']);

export const PROJECT_RE_PROJECT_RIGHTS_ENVIRONMENTAL_CLEARANCE_STATUS_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);
export const PROJECT_RE_PROJECT_RIGHTS_GRID_CONNECTIVITY_APPROVAL_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);
export const PROJECT_RE_PROJECT_RIGHTS_LAND_USE_PERMIT_OPTIONS = convertArrayToOptionType(['Approved', 'In Progress', 'Pending']);

export const PROJECT_RE_PROJECT_RIGHTS_TRANSMISSION_LINE_STATUS_OPTIONS = convertArrayToOptionType(['Work in Progress', 'Completed', 'Pending']);

export const PROJECT_RE_PROJECT_RIGHTS_FUNDING_SOURCE_OPTIONS = convertArrayToOptionType(['Self-Funded', 'Loan', 'Investment']);
export const PROJECT_RE_PROJECT_RIGHTS_POWER_PURCHASE_AGREEMENT_OPTIONS = convertArrayToOptionType(['Signed', 'In Progress', 'Pending']);
export const PROJECT_GENERAL_PROJECT_ORDER_TYPE_OPTIONS = convertArrayToOptionType(['Buy', 'Sell']);

export const STATES_OPTIONS = convertArrayToOptionType([
    "Maharashtra",
    "Uttar Pradesh",
])