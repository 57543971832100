import { AxiosResponse } from "axios";
import { axiosInstance } from "src/services/axiosSetup";
import * as API from "src/services/consts/Users";

export function getUsersAnalysisRequestApi(): Promise<any> {
    return axiosInstance.get(API.URL_USER_ANALYSIS).then((d: AxiosResponse) => d.data);
}

export function fetchAllUsersRequestApi(query: string): Promise<any> {
    query = query ? "?" + query : '';
    return axiosInstance.get(`${API.URL_USER_LIST}${query}`).then((d: AxiosResponse) => d.data);
}

export function getUsersDetailsByUserIdRequestApi(id: string): Promise<any> {
    return axiosInstance.get(API.URL_USER_DETAILS_BY_ID + "/" + id).then((d: AxiosResponse) => d.data);
}