
import { IndianRupee } from 'lucide-react';
import * as consts from 'src/helpers'

export interface GeneralProjectInfo {
    projectId: string;
    projectSize: string;
    projectType: string;
    serviceName: string;
    customerType: string,
    projectCategoryType: string;
    projectLocation: string;
    state: string;
    nearestCity: string;
    distanceFromNearestCity: string;
    nearestAirport: string;
    nearestRailwayStation: string;
    coordinates: string;
    orderType: string;
    channelPartnerId: string
}

export interface ProjectCapacity {
    totalCapacityMW: string;
    estimatedAnnualGenerationMWh: string;
}

export interface ProjectTimeline {
    phase1: string;
    phase2: string;
    phase3: string;
}

export interface PermitsAndApprovals {
    environmentalClearanceStatus: string;
    gridConnectivityApproval: string;
    landUsePermit: string;
}

export interface SubstationInformation {
    substationName: string;
    distanceFromSubstation: string;
}

export interface TransmissionLine {
    transmissionLineStatus: string;
    distanceFromProjectSite: string;
    voltageLevel: string;
}

export interface LandAcquisitionStatus {
    totalRequiredLand: string;
    landAcquired: string;
    acquisitionCompletionDate: string;
}

export interface FinancialInformation {
    projectCostEstimate: string;
    fundingSource: string;
    powerPurchaseAgreement: string;
    expectedPPAPrice: string;
}

export interface ProjectDocuments {
    landOwnershipDocuments: string[];
    environmentalImpactAssessment: string;
    gridConnectivityApproval: string;
    powerPurchaseAgreement: string;
    projectFeasibilityReport: string;
    projectFinancialModel: string;
}

export interface Project {
    capacity: ProjectCapacity;
    timeline: ProjectTimeline;
    permitsAndApprovals: PermitsAndApprovals;
    substationInformation: SubstationInformation;
    transmissionLine: TransmissionLine;
    landAcquisitionStatus: LandAcquisitionStatus;
    financialInformation: FinancialInformation;
    projectDocuments: ProjectDocuments;
}

export type re_project_rights =
    GeneralProjectInfo &
    ProjectTimeline &
    ProjectTimeline &
    PermitsAndApprovals &
    SubstationInformation &
    TransmissionLine &
    LandAcquisitionStatus &
    FinancialInformation &
    ProjectDocuments &
    Project;


export const generalProjectInfo: GeneralProjectInfo = {
    projectId: "",
    projectSize: "",
    projectType: "",
    serviceName: "",
    customerType: "",
    projectCategoryType: "",
    projectLocation: "",
    state: "",
    nearestCity: "",
    distanceFromNearestCity: "",
    nearestAirport: "",
    nearestRailwayStation: "",
    coordinates: "",
    orderType: "",
    channelPartnerId: ""
};


export const GENERAL_PROJECT_INFO_FORM_FIELDS: consts.FormField[] = [
    {
        label: 'Project Size',
        dataKey: 'projectSize',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Project Size',
            required: true
        },
    },
    {
        label: 'Project Type',
        dataKey: 'projectType',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_TYPES, // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Service Name',
        dataKey: 'serviceName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Service Name',
            required: true
        },
    },
    {
        label: 'Customer Type',
        dataKey: 'customerType',
        componentType: 'options',
        componentProps: {
            options: consts.CUSTOMER_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Project Category Type',
        dataKey: 'projectCategoryType',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Order Type',
        dataKey: 'orderType',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_GENERAL_PROJECT_ORDER_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Select Channel Partner',
        dataKey: 'channelPartnerId',
        componentType: 'options',
        componentProps: {
            options: [],
            required: true
        },
    },
    {
        label: 'Project Location',
        dataKey: 'projectLocation',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Project Location',
            required: true
        },
    },
    {
        label: 'State',
        dataKey: 'state',
        componentType: 'options',
        componentProps: {
            options: consts.STATES_OPTIONS,
            required: true
        },
    },
    {
        label: 'Nearest City',
        dataKey: 'nearestCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest City',
            required: true
        },
    },
    {
        label: 'Distance From Nearest City (km)',
        dataKey: 'distanceFromNearestCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Distance From Nearest City (km)',
            required: true
        },
    },
    {
        label: 'Nearest Airport',
        dataKey: 'nearestAirport',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Airport',
            required: true
        },
    },
    {
        label: 'Nearest Railway Station',
        dataKey: 'nearestRailwayStation',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest Railway Station',
            required: true
        },
    },
    {
        label: 'Coordinates (lat,long)',
        dataKey: 'coordinates',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Coordinates (lat,long)',
            required: true,
            regex: consts.LAT_LONG_REGEX
        },
    },
];

export const projectCapacity: ProjectCapacity = {
    totalCapacityMW: "",
    estimatedAnnualGenerationMWh: "",
};

export const projectTimeline: ProjectTimeline = {
    phase1: "",
    phase2: "",
    phase3: "",
};

export const permitsAndApprovals: PermitsAndApprovals = {
    environmentalClearanceStatus: "",
    gridConnectivityApproval: "",
    landUsePermit: "",
};

export const substationInformation: SubstationInformation = {
    substationName: "",
    distanceFromSubstation: "",
};

export const transmissionLine: TransmissionLine = {
    transmissionLineStatus: "",
    distanceFromProjectSite: "",
    voltageLevel: "",
};

export const landAcquisitionStatus: LandAcquisitionStatus = {
    totalRequiredLand: "",
    landAcquired: "",
    acquisitionCompletionDate: "",
};

export const financialInformation: FinancialInformation = {
    projectCostEstimate: "",
    fundingSource: "",
    powerPurchaseAgreement: "",
    expectedPPAPrice: "",
};

export const projectDocuments: ProjectDocuments = {
    landOwnershipDocuments: [],
    environmentalImpactAssessment: "",
    gridConnectivityApproval: "",
    powerPurchaseAgreement: "",
    projectFeasibilityReport: "",
    projectFinancialModel: "",
};

export const project: Project = {
    capacity: projectCapacity,
    timeline: projectTimeline,
    permitsAndApprovals: permitsAndApprovals,
    substationInformation: substationInformation,
    transmissionLine: transmissionLine,
    landAcquisitionStatus: landAcquisitionStatus,
    financialInformation: financialInformation,
    projectDocuments: projectDocuments,
};

export const RE_PROJECT_RIGHTS_INIT: re_project_rights = {
    ...generalProjectInfo,
    ...projectCapacity,
    ...projectTimeline,
    ...permitsAndApprovals,
    ...substationInformation,
    ...transmissionLine,
    ...landAcquisitionStatus,
    ...financialInformation,
    ...projectDocuments,
    ...project,
};


export const RE_PROJECT_RIGHTS_FORM_SECTIONS = {
    "GENERAL_PROJECT_INFO": generalProjectInfo,
    "PROJECT_CAPACITY": projectCapacity,
    "PROJECT_TIMELINE": projectTimeline,
    "PERMITS_AND_APPROVALS": permitsAndApprovals,
    "SUBSTATION_INFORMATION": substationInformation,
    "TRANSMISSION_LINE": transmissionLine,
    "LAND_ACQUISITION_STATUS": landAcquisitionStatus,
    "FINANCIAL_INFORMATION": financialInformation,
    "PROJECT_DOCUMENTS": projectDocuments,
}

export const RE_PROJECT_RIGHTS_FORM_FIELDS: consts.FormField[] = [
    {
        label: 'Total Capacity (MW)',
        dataKey: 'totalCapacityMW',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Total Capacity (MW)',
            required: true
        },
    },
    {
        label: 'Estimated Annual Generation (MWh)',
        dataKey: 'estimatedAnnualGenerationMWh',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Estimated Annual Generation (MWh)',
            required: true
        },
    },
    {
        label: 'Phase 1',
        dataKey: 'phase1',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phase 1 Details',
            required: true
        },
    },
    {
        label: 'Phase 2',
        dataKey: 'phase2',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phase 2 Details',
            required: true
        },
    },
    {
        label: 'Phase 3',
        dataKey: 'phase3',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Phase 3 Details',
            required: true
        },
    },
    {
        label: 'Environmental Clearance Status',
        dataKey: 'environmentalClearanceStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_RIGHTS_ENVIRONMENTAL_CLEARANCE_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Grid Connectivity Approval',
        dataKey: 'gridConnectivityApproval',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_RIGHTS_GRID_CONNECTIVITY_APPROVAL_OPTIONS,
            required: true
        },
    },
    {
        label: 'Land Use Permit',
        dataKey: 'landUsePermit',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_RIGHTS_LAND_USE_PERMIT_OPTIONS,
            required: true
        },
    },
    {
        label: 'Substation Name',
        dataKey: 'substationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Substation Name',
            required: true
        },
    },
    {
        label: 'Distance From Substation (km)',
        dataKey: 'distanceFromSubstation',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Distance From Substation (km)',
            required: true
        },
    },
    {
        label: 'Transmission Line Status',
        dataKey: 'transmissionLineStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_RIGHTS_TRANSMISSION_LINE_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Distance From Project Site (km)',
        dataKey: 'distanceFromProjectSite',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Distance From Project Site (km)',
            required: true
        },
    },
    {
        label: 'Voltage Level (kV)',
        dataKey: 'voltageLevel',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Voltage Level (kV)',
            required: true
        },
    },
    {
        label: 'Total Required Land (ha)',
        dataKey: 'totalRequiredLand',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Total Required Land (ha)',
            required: true
        },
    },
    {
        label: 'Land Acquired (ha)',
        dataKey: 'landAcquired',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Land Acquired (ha)',
            required: true
        },
    },
    {
        label: 'Acquisition Completion Date',
        dataKey: 'acquisitionCompletionDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Acquisition Completion Date',
            required: true
        },
    },
    {
        label: 'Project Cost Estimate (INR)',
        dataKey: 'projectCostEstimate',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Project Cost Estimate (INR)',
            inputPrefixIcon: <IndianRupee size={16} />,
            required: true
        },
    },
    {
        label: 'Funding Source',
        dataKey: 'fundingSource',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_RIGHTS_FUNDING_SOURCE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Power Purchase Agreement',
        dataKey: 'powerPurchaseAgreement',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_RIGHTS_POWER_PURCHASE_AGREEMENT_OPTIONS,
            required: true
        },
    },
    {
        label: 'Expected PPA Price (INR)',
        dataKey: 'expectedPPAPrice',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Expected PPA Price (INR)',
            inputPrefixIcon: <IndianRupee size={16} />,
            required: true
        },
    },
    {
        label: 'Land Ownership Documents',
        dataKey: 'landOwnershipDocuments',
        componentType: 'file_picker',
        componentProps: {
            fileSelectionType: 'multi',
            placeholder: 'Select Land Ownership Documents',
            required: true
        },
    },
    {
        label: 'Environmental Impact Assessment',
        dataKey: 'environmentalImpactAssessment',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select Environmental Impact Assessment Document',
            required: true
        },
    },
    {
        label: 'Project Feasibility Report',
        dataKey: 'projectFeasibilityReport',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select Project Feasibility Report',
            required: true
        },
    },
    {
        label: 'Project Financial Model',
        dataKey: 'projectFinancialModel',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Select Project Financial Model',
            required: true
        },
    },
];

export interface LocationDetails {
    projectLocationName: string;
    address: string;
    nearestCity: string;
    distanceFromNearestCity: string;
    coordinates: {
        latitude: string;
        longitude: string;
    };
}

export const locationDetails: LocationDetails = {
    projectLocationName: "",
    address: "",
    nearestCity: "",
    distanceFromNearestCity: "",
    coordinates: {
        latitude: "",
        longitude: "",
    },
};

export interface PropertyDetails {
    landType: string;
    totalRequiredLand: string;
    acquiredLand: string;
    balanceLand: string;
}

export const propertyDetails: PropertyDetails = {
    landType: "",
    totalRequiredLand: "",
    acquiredLand: "",
    balanceLand: "",
};


export interface ConstructionTimeline {
    startDate: Date | null;
    expectedCompletionDate: Date | null;
    currentConstructionStatus: string;
    workCompletedPercentage: string;
    constructionPhase: string
}

export const constructionTimeline: ConstructionTimeline = {
    startDate: null,
    expectedCompletionDate: null,
    currentConstructionStatus: "",
    workCompletedPercentage: "",
    constructionPhase: "",
};

export interface SubstationTransmission {
    substationName: string;
    distanceFromSubstation: string;
    transmissionLineStatus: string;
    expectedCompletionDate: Date | null;
}

export const substationTransmission: SubstationTransmission = {
    substationName: "",
    distanceFromSubstation: "",
    transmissionLineStatus: "",
    expectedCompletionDate: null,
};


export interface ApprovalPermits {
    permitsRequired: string;
    permitStatus: string;
    permitDocumentUpload: File | null;
}

export const approvalPermits: ApprovalPermits = {
    permitsRequired: "",
    permitStatus: "",
    permitDocumentUpload: null,
};

export interface ConstructionCosts {
    estimatedProjectCost: string;
}


export const constructionCosts: ConstructionCosts = {
    estimatedProjectCost: "",
};

export type project_construction =
    GeneralProjectInfo &
    PropertyDetails &
    ConstructionTimeline &
    SubstationTransmission &
    ApprovalPermits &
    ConstructionCosts


export const PROJECT_CONSTRUCTION_INIT: project_construction = {
    ...generalProjectInfo,
    ...propertyDetails,
    ...constructionTimeline,
    ...substationTransmission,
    ...approvalPermits,
    ...constructionCosts,
};



export const PROJECT_CONSTRUCTION_FORM_SECTIONS = {
    "GENERAL_PROJECT_INFO": generalProjectInfo,
    "PROPERTY_DETAILS": propertyDetails,
    "CONSTRUCTION_TIMELINE": constructionTimeline,
    "SUBSTATION_TRANSMISSION": substationTransmission,
    "APPROVAL_PERMITS": approvalPermits,
    "CONSTRUCTION_COSTS": constructionCosts,
};


export const PROJECT_CONSTRUCTION_FORM_FIELDS: consts.FormField[] = [
    {
        label: 'Land Type',
        dataKey: 'landType',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_PROJECT_CONSTRUCTION_PROPERTY_DETAILS_LAND_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Total Required Land (ha)',
        dataKey: 'totalRequiredLand',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Total Required Land (ha)',
            required: true
        },
    },
    {
        label: 'Acquired Land (ha)',
        dataKey: 'acquiredLand',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Acquired Land (ha)',
            required: true
        },
    },
    {
        label: 'Balance Land (ha)',
        dataKey: 'balanceLand',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Balance Land (ha)',
            required: true
        },
    },
    {
        label: 'Start Date',
        dataKey: 'startDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Start Date',
            required: true
        },
    },
    {
        label: 'Expected Completion Date',
        dataKey: 'expectedCompletionDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Expected Completion Date',
            required: true
        },
    },
    {
        label: 'Current Construction Status',
        dataKey: 'currentConstructionStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_PROJECT_CONSTRUCTION_CONSTRUCTION_TIMELINE_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Work Completed Percentage (%)',
        dataKey: 'workCompletedPercentage',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Work Completed Percentage (%)',
            required: true
        },
    },
    {
        label: 'Construction Phase',
        dataKey: 'constructionPhase',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_PROJECT_CONSTRUCTION_CONSTRUCTION_PHASE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Substation Name',
        dataKey: 'substationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Substation Name',
            required: true
        },
    },
    {
        label: 'Distance From Substation (km)',
        dataKey: 'distanceFromSubstation',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Distance From Substation (km)',
            required: true
        },
    },
    {
        label: 'Transmission Line Status',
        dataKey: 'transmissionLineStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_PROJECT_CONSTRUCTION_SUBSTATION_TRANSMISSION_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Transmission Line Expected Completion Date',
        dataKey: 'transmissionLineExpectedCompletionDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Transmission Line Expected Completion Date',
            required: true
        },
    },
    {
        label: 'Permits Required',
        dataKey: 'permitsRequired',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_PROJECT_CONSTRUCTION_APPROVAL_PERMITS_REQUIRED_OPTIONS,
            required: true
        },
    },
    {
        label: 'Permit Status',
        dataKey: 'permitStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_PROJECT_CONSTRUCTION_PERMIT_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Permit Document Upload',
        dataKey: 'permitDocumentUpload',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Permit Document',
            required: true
        },
    },
    {
        label: 'Estimated Project Cost (INR)',
        dataKey: 'estimatedProjectCost',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Estimated Project Cost (INR)',
            inputPrefixIcon: <IndianRupee size={16} />,
            required: true
        },
    },
];

export interface BuildingDetails {
    buildingName: string;
    buildingType: string;
    roofType: string;
    roofCondition: string;
    roofAreaAvailable: string;
    heightOfBuilding: string;
}

export const buildingDetails: BuildingDetails = {
    buildingName: "",
    buildingType: "",
    roofType: "",
    roofCondition: "",
    roofAreaAvailable: "",
    heightOfBuilding: "",
};

export interface OwnershipStatus {
    ownershipStatus: string;
    acquisitionStatus: string;
    expectedCompletionDate?: Date | null;
}

export const ownershipStatus: OwnershipStatus = {
    ownershipStatus: "",
    acquisitionStatus: "",
    expectedCompletionDate: null,
};

export interface SystemInformation {
    proposedCapacity: string;
    typeOfSystem: string;
}

export const systemInformation: SystemInformation = {
    proposedCapacity: "",
    typeOfSystem: "",
};


export interface RoofInformation {
    totalRoofAreaRequired: string;
    acquiredRoofArea: string;
    balanceRoofArea: string;
}

export const roofInformation: RoofInformation = {
    totalRoofAreaRequired: "",
    acquiredRoofArea: "",
    balanceRoofArea: "",
};

export interface c_and_i_Documents {
    ownershipDocument: File | null;
    netMeteringApprovalDocument: File | null;
    structuralSafetyCertificate: File | null;
    installationAgreement: File | null;
}


export const c_and_i_Documents: c_and_i_Documents = {
    ownershipDocument: null,
    netMeteringApprovalDocument: null,
    structuralSafetyCertificate: null,
    installationAgreement: null,
};

export type c_and_i_projects =
    GeneralProjectInfo &
    BuildingDetails &
    OwnershipStatus &
    SystemInformation &
    RoofInformation &
    c_and_i_Documents



export const C_AND_I_PROJECTS_INIT: c_and_i_projects = {
    ...generalProjectInfo,
    ...buildingDetails,
    ...ownershipStatus,
    ...systemInformation,
    ...roofInformation,
    ...c_and_i_Documents
}

export const C_AND_I_PROJECTS_FORM_SECTIONS = {
    "GENERAL_PROJECT_INFO": generalProjectInfo,
    "BUILDING_DETAILS": buildingDetails,
    "OWNERSHIP_STATUS": ownershipStatus,
    "SYSTEM_INFORMATION": systemInformation,
    "ROOF_INFORMATION": roofInformation,
    "C_AND_I_DOCUMENT": c_and_i_Documents
}

export const C_AND_I_PROJECTS_FORM_FIELDS: consts.FormField[] = [
    {
        label: 'Building Name',
        dataKey: 'buildingName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Building Name',
            required: true
        },
    },
    {
        label: 'Building Type',
        dataKey: 'buildingType',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_CI_AND_RE_PROJECTS_BUILDING_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Roof Type',
        dataKey: 'roofType',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_CI_AND_RE_PROJECTS_ROOF_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Roof Condition',
        dataKey: 'roofCondition',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_CI_AND_RE_PROJECTS_ROOF_CONDITION_OPTIONS,
            required: true
        },
    },
    {
        label: 'Roof Area Available (sq. m)',
        dataKey: 'roofAreaAvailable',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Roof Area Available (sq. m)',
            required: true
        },
    },
    {
        label: 'Height of Building (m)',
        dataKey: 'heightOfBuilding',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Height of Building (m)',
            required: true
        },
    },
    {
        label: 'Ownership Status',
        dataKey: 'ownershipStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_CI_AND_RE_PROJECTS_OWNERSHIP_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Acquisition Status',
        dataKey: 'acquisitionStatus',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_CI_AND_RE_PROJECTS_ACQUISITION_STATUS_OPTIONS,
            required: true
        },
    },
    {
        label: 'Expected Completion Date',
        dataKey: 'expectedCompletionDate',
        componentType: 'date_picker',
        componentProps: {
            placeholder: 'Expected Completion Date',
            required: false
        },
    },
    {
        label: 'Proposed Capacity (kW)',
        dataKey: 'proposedCapacity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Proposed Capacity (kW)',
            required: true
        },
    },
    {
        label: 'Type of System',
        dataKey: 'typeOfSystem',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_CI_AND_RE_PROJECTS_SYSTEM_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Total Roof Area Required (sq. m)',
        dataKey: 'totalRoofAreaRequired',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Total Roof Area Required (sq. m)',
            required: true
        },
    },
    {
        label: 'Acquired Roof Area (sq. m)',
        dataKey: 'acquiredRoofArea',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Acquired Roof Area (sq. m)',
            required: true
        },
    },
    {
        label: 'Balance Roof Area (sq. m)',
        dataKey: 'balanceRoofArea',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Balance Roof Area (sq. m)',
            required: true
        },
    },
    {
        label: 'Ownership Document',
        dataKey: 'ownershipDocument',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Ownership Document',
            required: true
        },
    },
    {
        label: 'Net Metering Approval Document',
        dataKey: 'netMeteringApprovalDocument',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Net Metering Approval Document',
            required: true
        },
    },
    {
        label: 'Structural Safety Certificate',
        dataKey: 'structuralSafetyCertificate',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Structural Safety Certificate',
            required: true
        },
    },
    {
        label: 'Installation Agreement',
        dataKey: 'installationAgreement',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Installation Agreement',
            required: true
        },
    },
];

export interface AboutCompany {
    companyName: string;
    companyRating: string;
    rolePosition: string;
    industryType: string | string;
    businessDescription: string;
}

export const aboutCompany: AboutCompany = {
    companyName: "",
    companyRating: "",
    rolePosition: "",
    industryType: "",
    businessDescription: "",
};

export interface EnergyRequirements {
    currentEnergyConsumption: string;
    peakEnergyDemand: string;
    averageMonthlyElectricityBill: string;
    currentElectricityTariff: string;
    preferredRenewableEnergySource: string;
}

export const energyRequirements: EnergyRequirements = {
    currentEnergyConsumption: "",
    peakEnergyDemand: "",
    averageMonthlyElectricityBill: "",
    currentElectricityTariff: "",
    preferredRenewableEnergySource: "",
};

export interface EnergyPurchasePreferences {
    preferredEnergyContractDuration: string;
    interestedInPPA: string;
    ppaTermPreferred: string;
    maximumPriceWillingToPay: string;
    willingToParticipateInP2PTrading: string;
    requiredSolarPlantCapacity: string;
    preferredConnectionVoltage: string;
}


export const energyPurchasePreferences: EnergyPurchasePreferences = {
    preferredEnergyContractDuration: "",
    interestedInPPA: "",
    ppaTermPreferred: "",
    maximumPriceWillingToPay: "",
    willingToParticipateInP2PTrading: "",
    requiredSolarPlantCapacity: "",
    preferredConnectionVoltage: "",
};


export interface SustainabilityGoals {
    hasSustainabilityGoals: string;
    carbonReductionTarget: string;
    targetRenewableEnergyShare: string;
    renewableEnergyCertificationRequired: string;
}

export const sustainabilityGoals: SustainabilityGoals = {
    hasSustainabilityGoals: "",
    carbonReductionTarget: "",
    targetRenewableEnergyShare: "",
    renewableEnergyCertificationRequired: "",
};


export interface LandingLocation {
    nearestCity: string;
    latitudeLongitude: string;
    existingSubstationConnectionVoltage: string;
    distanceFromSubstation: string;
}

export const landingLocation: LandingLocation = {
    nearestCity: "",
    latitudeLongitude: "",
    existingSubstationConnectionVoltage: "",
    distanceFromSubstation: "",
};

export interface re_project_capacity_documents {
    recentElectricityBills: File[] | string[] | null;
    companyRatingReport: File | string | null;
}


export const projectCapacityDocuments: re_project_capacity_documents = {
    recentElectricityBills: null,
    companyRatingReport: null,
};


export type re_project_capacity =
    GeneralProjectInfo &
    AboutCompany &
    EnergyRequirements &
    EnergyPurchasePreferences &
    SustainabilityGoals &
    LandingLocation &
    re_project_capacity_documents;

export const RE_PROJECT_CAPACITY_INIT: re_project_capacity = {
    ...generalProjectInfo,
    ...aboutCompany,
    ...energyRequirements,
    ...energyPurchasePreferences,
    ...sustainabilityGoals,
    ...landingLocation,
    ...projectCapacityDocuments,
};

export const RE_PROJECT_CAPACITY_FORM_SECTIONS = {
    "GENERAL_PROJECT_INFO": generalProjectInfo,
    "ABOUT_COMPANY": aboutCompany,
    "ENERGY_REQUIREMENTS": energyRequirements,
    "ENERGY_PURCHASE_PREFERENCES": energyPurchasePreferences,
    "SUSTAINABILITY_GOALS": sustainabilityGoals,
    "LANDING_LOCATION": landingLocation,
    "PROJECT_CAPACITY_DOCUMENTS": projectCapacityDocuments,
};

export const RE_PROJECT_CAPACITY_FORM_FIELDS: consts.FormField[] = [
    {
        label: 'Company Name',
        dataKey: 'companyName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Company Name',
            required: true
        },
    },
    {
        label: 'Company Rating',
        dataKey: 'companyRating',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_COMPANY_RATING_OPTIONS,
            required: true
        },
    },
    {
        label: 'Role/Position',
        dataKey: 'rolePosition',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Role/Position',
            required: true
        },
    },
    {
        label: 'Industry Type',
        dataKey: 'industryType',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_INDUSTRY_TYPE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Business Description',
        dataKey: 'businessDescription',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Business Description',
            required: true
        },
    },
    {
        label: 'Current Energy Consumption (kWh)',
        dataKey: 'currentEnergyConsumption',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Current Energy Consumption (kWh)',
            required: true
        },
    },
    {
        label: 'Peak Energy Demand (kW)',
        dataKey: 'peakEnergyDemand',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Peak Energy Demand (kW)',
            required: true
        },
    },
    {
        label: 'Average Monthly Electricity Bill (INR)',
        dataKey: 'averageMonthlyElectricityBill',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Average Monthly Electricity Bill (INR)',
            inputPrefixIcon: <IndianRupee size={16} />,
            required: true
        },
    },
    {
        label: 'Current Electricity Tariff (INR/kWh)',
        dataKey: 'currentElectricityTariff',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Current Electricity Tariff (INR/kWh)',
            inputPrefixIcon: <IndianRupee size={16} />,
            required: true
        },
    },
    {
        label: 'Preferred Renewable Energy Source',
        dataKey: 'preferredRenewableEnergySource',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_PREFERRED_RENEWABLE_ENERGY_SOURCE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Preferred Energy Contract Duration',
        dataKey: 'preferredEnergyContractDuration',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_PREFERRED_ENERGY_CONTRACT_DURATION_OPTIONS,
            required: true
        },
    },
    {
        label: 'Interested in PPA',
        dataKey: 'interestedInPPA',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_INTERESTED_IN_PPA_OPTIONS,
            required: true
        },
    },
    {
        label: 'PPA Term Preferred',
        dataKey: 'ppaTermPreferred',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_PPA_TERM_PREFERRED_OPTIONS,
            required: true
        },
    },
    {
        label: 'Maximum Price Willing to Pay (INR/kWh)',
        dataKey: 'maximumPriceWillingToPay',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Maximum Price Willing to Pay (INR/kWh)',
            inputPrefixIcon: <IndianRupee size={16} />,
            required: true
        },
    },
    {
        label: 'Willing to Participate in P2P Trading',
        dataKey: 'willingToParticipateInP2PTrading',
        componentType: 'options',
        componentProps: {
            options: consts.YES_NO_OPTIONS,
            required: true
        },
    },
    {
        label: 'Required Solar Plant Capacity (kW)',
        dataKey: 'requiredSolarPlantCapacity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Required Solar Plant Capacity (kW)',
            required: true
        },
    },
    {
        label: 'Preferred Connection Voltage (kV)',
        dataKey: 'preferredConnectionVoltage',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_PREFERRED_CONNECTION_VOLTAGE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Has Sustainability Goals',
        dataKey: 'hasSustainabilityGoals',
        componentType: 'options',
        componentProps: {
            options: consts.YES_NO_OPTIONS,
            required: true
        },
    },
    {
        label: 'Carbon Reduction Target (%)',
        dataKey: 'carbonReductionTarget',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Carbon Reduction Target (%)',
            required: true
        },
    },
    {
        label: 'Target Renewable Energy Share (%)',
        dataKey: 'targetRenewableEnergyShare',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Target Renewable Energy Share (%)',
            required: true
        },
    },
    {
        label: 'Renewable Energy Certification Required',
        dataKey: 'renewableEnergyCertificationRequired',
        componentType: 'options',
        componentProps: {
            options: consts.YES_NO_OPTIONS,
            required: true
        },
    },
    {
        label: 'Nearest City',
        dataKey: 'nearestCity',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Nearest City',
            required: true
        },
    },
    {
        label: 'Latitude/Longitude',
        dataKey: 'latitudeLongitude',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Latitude, Longitude',
            required: true,
            regex: consts.LAT_LONG_REGEX
        },
    },
    {
        label: 'Existing Substation Connection Voltage (kV)',
        dataKey: 'existingSubstationConnectionVoltage',
        componentType: 'options',
        componentProps: {
            options: consts.PROJECT_RE_PROJECT_CAPACITY_EXISTING_SUBSTATION_CONNECTION_VOLTAGE_OPTIONS,
            required: true
        },
    },
    {
        label: 'Distance From Substation (km)',
        dataKey: 'distanceFromSubstation',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Distance From Substation (km)',
            required: true
        },
    },
    {
        label: 'Recent Electricity Bills',
        dataKey: 'recentElectricityBills',
        componentType: 'file_picker',
        componentProps: {
            fileSelectionType: 'multi',
            placeholder: 'Upload Recent Electricity Bills',
            required: true
        },
    },
    {
        label: 'Company Rating Report',
        dataKey: 'companyRatingReport',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Company Rating Report',
            required: true
        },
    },
];

export interface LandProject {
    landLocation: string;
    areaLand: string;
    typeOfLand: string;
    privateLand: string;
    govtLands: string;
    coordinates: string;
    landMap: string;
    gutNo: string;
    terrainPlain: string;
    landSoil: string;
    adjoiningArea: string;
    approachFromNearestRoad: string;
    stuSubstationName: string;
    voltageRating: string;
    evacuationVoltage: string;
    stuCoordinates: string;
    landAndSTUDistance: string;
    sparePowerEvacuationCapacity: string;
    passingOfHtLtTransmissionLineGasWaterPipelineLand: string;
    lengthOfTransmissionLine: string;
    voltageLevelOfTransmissionLine: string;
    transmissionLineCircuitType: string;
    waterBodies: string;
    detailsOfSolarPowerPlant: string;
    additionalPointsOfferedByEPC: string;
}

export const land_project: LandProject = {
    landLocation: "",
    areaLand: "",
    typeOfLand: "",
    privateLand: "",
    govtLands: "",
    coordinates: "",
    landMap: "",
    gutNo: "",
    terrainPlain: "",
    landSoil: "",
    adjoiningArea: "",
    approachFromNearestRoad: "",
    stuSubstationName: "",
    voltageRating: "",
    evacuationVoltage: "",
    stuCoordinates: "",
    landAndSTUDistance: "",
    sparePowerEvacuationCapacity: "",
    passingOfHtLtTransmissionLineGasWaterPipelineLand: "",
    lengthOfTransmissionLine: "",
    voltageLevelOfTransmissionLine: "",
    transmissionLineCircuitType: "",
    waterBodies: "",
    detailsOfSolarPowerPlant: "",
    additionalPointsOfferedByEPC: "",
};


export type landForReProjects =
    GeneralProjectInfo &
    LandProject;

export const LAND_RE_PROJECTS_INIT = {
    ...generalProjectInfo,
    ...land_project
}


export const LAND_RE_PROJECTS_FORM_SECTIONS = {
    "GENERAL_PROJECT_INFO": generalProjectInfo,
    "LAND_PROJECT": land_project
}

export const LAND_PROJECT_FORM_FIELDS: consts.FormField[] = [
    {
        label: 'Land Location',
        dataKey: 'landLocation',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Land Location',
            required: true
        },
    },
    {
        label: 'Area of Land (ha)',
        dataKey: 'areaLand',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Area of Land (ha)',
            required: true
        },
    },
    {
        label: 'Type of Land',
        dataKey: 'typeOfLand',
        componentType: 'options',
        componentProps: {
            options: [], // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Private Land',
        dataKey: 'privateLand',
        componentType: 'options',
        componentProps: {
            options: consts.YES_NO_OPTIONS, // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Government Lands',
        dataKey: 'govtLands',
        componentType: 'options',
        componentProps: {
            options: consts.YES_NO_OPTIONS, // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Coordinates',
        dataKey: 'coordinates',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Coordinates (lat,long)',
            required: true,
            regex: consts.LAT_LONG_REGEX // Define this regex based on your requirements
        },
    },
    {
        label: 'Land Map',
        dataKey: 'landMap',
        componentType: 'file_picker',
        componentProps: {
            placeholder: 'Upload Land Map',
            required: true
        },
    },
    {
        label: 'GUT No.',
        dataKey: 'gutNo',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'GUT No.',
            required: true
        },
    },
    {
        label: 'Terrain Plain',
        dataKey: 'terrainPlain',
        componentType: 'options',
        componentProps: {
            options: [], // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Land Soil',
        dataKey: 'landSoil',
        componentType: 'options',
        componentProps: {
            options: [], // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Adjoining Area',
        dataKey: 'adjoiningArea',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Adjoining Area',
            required: true
        },
    },
    {
        label: 'Approach From Nearest Road',
        dataKey: 'approachFromNearestRoad',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Approach From Nearest Road',
            required: true
        },
    },
    {
        label: 'STU Substation Name',
        dataKey: 'stuSubstationName',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'STU Substation Name',
            required: true
        },
    },
    {
        label: 'Voltage Rating (kV)',
        dataKey: 'voltageRating',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Voltage Rating (kV)',
            required: true
        },
    },
    {
        label: 'Evacuation Voltage (kV)',
        dataKey: 'evacuationVoltage',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Evacuation Voltage (kV)',
            required: true
        },
    },
    {
        label: 'STU Coordinates',
        dataKey: 'stuCoordinates',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'STU Coordinates (lat,long)',
            required: true,
            regex: consts.LAT_LONG_REGEX // Define this regex based on your requirements
        },
    },
    {
        label: 'Land and STU Distance (km)',
        dataKey: 'landAndSTUDistance',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Land and STU Distance (km)',
            required: true
        },
    },
    {
        label: 'Spare Power Evacuation Capacity (MW)',
        dataKey: 'sparePowerEvacuationCapacity',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Spare Power Evacuation Capacity (MW)',
            required: true
        },
    },
    {
        label: 'Passing of HT/LT Transmission Line/Gas/Water Pipeline Land',
        dataKey: 'passingOfHtLtTransmissionLineGasWaterPipelineLand',
        componentType: 'options',
        componentProps: {
            options: consts.YES_NO_OPTIONS, // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Length of Transmission Line (km)',
        dataKey: 'lengthOfTransmissionLine',
        componentType: 'input',
        componentProps: {
            type: "text",
            regex: /[^0-9]+/g,
            numberFormatted: true,
            placeholder: 'Length of Transmission Line (km)',
            required: true
        },
    },
    {
        label: 'Voltage Level of Transmission Line (kV)',
        dataKey: 'voltageLevelOfTransmissionLine',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Voltage Level of Transmission Line (kV)',
            required: true
        },
    },
    {
        label: 'Transmission Line Circuit Type',
        dataKey: 'transmissionLineCircuitType',
        componentType: 'options',
        componentProps: {
            options: [], // Define this array based on your requirements
            required: true
        },
    },
    {
        label: 'Water Bodies',
        dataKey: 'waterBodies',
        componentType: 'input',
        componentProps: {
            type: "text",
            placeholder: 'Water Bodies',
            required: true
        },
    },
    {
        label: 'Details of Solar Power Plant',
        dataKey: 'detailsOfSolarPowerPlant',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Details of Solar Power Plant',
            required: true
        },
    },
    {
        label: 'Additional Points Offered by EPC',
        dataKey: 'additionalPointsOfferedByEPC',
        componentType: 'textarea',
        componentProps: {
            placeholder: 'Additional Points Offered by EPC',
            required: true
        },
    },
];


export const PROJECT_CATEGORY_WISE_FORM_STRUCTURE = {
    "RE Project Capacity": RE_PROJECT_CAPACITY_FORM_SECTIONS,
    "RE Project Rights": RE_PROJECT_RIGHTS_FORM_SECTIONS,
    "Land for RE Projects": LAND_RE_PROJECTS_FORM_SECTIONS,
    "New RE Project Construction": PROJECT_CONSTRUCTION_FORM_SECTIONS,
    "C&I RE Projects": C_AND_I_PROJECTS_FORM_SECTIONS
}


export const PROJECT_CATEGORY_WISE_FORM_FIELD = {
    "RE Project Capacity": RE_PROJECT_CAPACITY_FORM_FIELDS,
    "RE Project Rights": RE_PROJECT_RIGHTS_FORM_FIELDS,
    "Land for RE Projects": LAND_PROJECT_FORM_FIELDS,
    "New RE Project Construction": PROJECT_CONSTRUCTION_FORM_FIELDS,
    "C&I RE Projects": C_AND_I_PROJECTS_FORM_FIELDS
}


